<template>
  <div class="user page">
    <Nav></Nav>
    <main class="wrap">
      <div class="content">
        <a-result status="success" title="支付成功">
          <template #subTitle>
            <div class="subtitle">
              ￥{{price}}
            </div>
          </template>
          <template #extra>
            <div class="btnarea">
                  <div class="btn">
                    <a-button type="primary" shape="round" size="large" @click="onSubmit" block>完成</a-button>
                  </div>
                </div>
          </template>
        </a-result>
      </div>
    </main>
    <Foot></Foot>
</div>
</template>
<script>


import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";

export default {
  components: {
    Nav,
    Foot,
  },
  data() {
    return {
      price:0,
      orderNo:'',
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = getqyinfo.split("&");
    let obj = {}; //创建空对象，接收截取的参数
    for (let i = 0; i < getqys.length; i++) {
      let item = getqys[i].split("=");
      let key = item[0];
      let value = item[1];
      obj[key] = value;
    }
    this.orderNo = obj.orderNo;
    this.loadData();
  },
  mounted() {

  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    go(e) {
      this.$router.push(e);
    },
    loadData() {
      this.api
        .getorderdetail({
          orderNo: this.orderNo,
        })
        .then((res) => {
          this.price = res.amountPay;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit(){
      this.$router.push('/orderDetail?id='+this.orderNo);
    }
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.content {
  padding-top: 30px;
}

.subtitle {
  font-size: 36px;
  color: #ff5717;
}

.btnarea {
    position: relative;
    width: 100%;
    padding-top: 30px;
    display: flex;
    text-align: center;
    justify-content: center;

    .btn {
      position: relative;
      width: 20%;
      text-align: center;
    }
  }
</style>